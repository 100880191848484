.courseManager {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 40px 20px;
    background-color: white;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
}

.courseManager > hr{
   margin-top: -10px
}

.courseManager > p{
    margin-top: 20px;
    margin-bottom: 30px;
 }


 .courseManager__header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 }

.courseManager__timing {
    margin-right: 5px;
}

.courseManager__lang__box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    align-self: center;
    font-size: 14px;
}

.courseManager__tableContainer {
   display:flex;
   align-items: center;
   /* justify-content: center; */
}

.courseManager__courseNameRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.courseManager__courseNameRow > span {
    margin-left: 5px;
}

.courseManager__cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
}


.customCard_expandDescription {
    margin-right: 15px;
}