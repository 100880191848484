.footer__container {
  /* position: fixed;
    bottom: 0;
    left: 0; */

  /* position: absolute;
  left: 0;
  bottom: 0;
  right: 0; */
  margin-top: 30px;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e0dfdb;
  display: flex;
}
