.batchManager {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 40px 20px;
    background-color: white;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
}

.batchManager>hr {
    margin-top: -10px
}

.batchManager>p {
    margin-top: 20px;
    margin-bottom: 30px;
}

.batchManager__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.batchManager__tableContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}


.batchManager__alert {
    margin-bottom: 15px;
}