.enrollmentManager {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
}

.enrollmentManager > hr{
    margin-top: -10px
 }


.enrollmentManager__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.enrollmentManager__fullNameCol {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.enrollmentManager__fullNameCol > span {
    margin-left: 5px;
}