.courseForm {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.courseForm__timings {
    margin-top: 10px;
    width: 230px;
    border: 1px dashed #C4C4C4;
    border-radius: 5px;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: left;
}
 
.courseForm__timings > .courseForm__timings__chip {
    margin: 2.5px;
}

.courseForm__timings > .courseForm__timings__chip:hover {
    color: #1875d1;
    border-color: #1875d1;
}

.courseForm__timings {
    margin-bottom: 10px;
}
