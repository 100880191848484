.container___parent {
    /* display: flex; */
}

.container {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;   
    flex-direction: column;
}

.container__dummy__child {
    /* flex: 0.5; */
}

.left {
    justify-content: left;
    align-items: left;
    text-align: left;
}

.center {
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container > hr {
    margin-top: -10px
}

.container__heading {
    text-align: left;
}

.container__header__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}