.signin {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.signin__captcha {
    margin-top: 15px;
    width: 100%;
}

.signin__error {
    margin-bottom: 15px;
    
}

.signin__phoneinput .country-list {
    text-align: initial;
}

.signin__footer {
    margin-block: 30px;
    display: flex;
    flex-direction: column;
    color: #635035;
    font-size: 14px;
}

.signin__footer__love {
    color: #ff0808;
}

.signin__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signin__logo {
    height: 88px;
    object-fit: contain;
}