.mainAppBar__workingArea {
    background-color: #f3f2ee;
    display: flex;
}

.mainAppBar__logo {
    height: 60px;
    object-fit: scale-down
}

.mainAppBar__menuIcon {
    color: #635035;
}

.mainAppBar__heading {
    font-size: 28px;
    font-weight: 500 !important;
    color: #635035;
}


.mainAppBar__logo__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}


.mainAppBar__login_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainAppBar__no__current_user {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
}
