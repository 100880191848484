.sessionForm{
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sessionForm__sessionNumber__validator {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}

.sessionForm__sessionNumber__validator > span {
  font-size: 12px;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
}

.sessionForm__sessionNumber__validator__icon {
  margin-left: 14px;
  width: 20px;
}